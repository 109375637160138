.table {
  border: 3px solid;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr:nth-child(1) td, tr:nth-child(1) th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  &.no-index {
    tr:nth-child(1) td, tr:nth-child(1) th {
      &:nth-child(1) {
        width: auto;
      }
    }
  }

  tr.error {
    td {
      background-color: #FFBABA;
    }
  }
  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      width: 150px;
      min-width: 150px;
      max-width: 150px;
      input, .input__container, .input__preview {
        text-align: right;
      }
      .input__preview {
        margin-bottom: 0;
        p {
          display: block;
          width: 100%;
        }
      }
      &.red {
        color: red;
      }
    }
    &.persentase, &.nominal.persentase {
      text-align: right;
      min-width: 80px;
    }
    &.file {
      & a {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  tr.total {
    td { color:#000000; }
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
        font-weight: bold;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .border_double {
    border-right: 2px #000000 solid;
    border-left: 2px #000000 solid;
    &.border_double__left {
      border-right: 1px solid;
    }

    &.border_double__right {
      border-left: 1px solid;
    }
  }

  .sub_header {
    border-top: 3px solid;
    border-bottom: 3px solid;
  }

  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-filters {
  display: flex;
  & > * {
    margin-right: 1rem;
  }
  & > *:last-child {
    margin-right: 0;
  }
}

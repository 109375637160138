.button {
  &,
  &:link,
  &:visited {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    font-size: $font-size-default;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 8px 5px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s;
  }

  &:hover {
    background-color: $color-primary-light;
  }

  &:active {
    background-color: $color-primary-dark;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &__icon {
    margin-right: 5px;

    &--no-margin {
      margin-right: 0;
    }
  }
}

.button-transparent {
  &,
  &:link,
  &:visited {
    background-color: transparent;
    font-size: $font-size-default;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem 3rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s;
  }

  &:hover {
    background-color: $color-primary;
    color: $color-white;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.button-square {
  &,
  &:link,
  &:visited {
    width: 50px;
    height: 50px;
    background-color: transparent;
    font-size: $font-size-default;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s;
  }

  &:hover {
    background-color: $color-primary;
    color: $color-white;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.button-white {
  &,
  &:link,
  &:visited {
    background-color: $color-white;
    color: $color-primary-dark;
  }

  &:hover {
    background-color: $color-grey-light-1;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.buttons {
  display: flex;
  & > div {
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
}

.button-disabled {
  opacity: 0.65;
  pointer-events: none;
}

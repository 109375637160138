/*
COLORS

Primary: #eb2f64
Primary light: #FF3366
Primary dark: #BA265D

Grey light 1: #faf9f9
Grey light 2: #f4f2f2
Grey light 3: #f0eeee
Grey light 4: #ccc

Grey dark 1: #333
Grey dark 2: #777
Grey dark 3: #999

*/
@import url("https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap");
@import url(vendors/fontello/css/fontello_icons.css);
@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: none; }
  50% {
    transform: scale(1.05);
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.25); }
  100% {
    transform: scale(1);
    box-shadow: none; } }

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

* {
  margin: 0;
  padding: 0; }

*,
*::after,
*::before {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
  width: 100%; }
  @media only screen and (max-width: 68.75em) {
    html {
      font-size: 50%; } }

body {
  font-family: 'Muli', 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  background-color: #dddede;
  min-height: 100%; }

button {
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit; }
  button:active, button:focus {
    outline: none; }

h1, h2, h3 {
  font-family: 'Poppins', sans-serif;
  width: 100%; }

p {
  font-size: 1.6rem; }

table thead th {
  background: #909090;
  color: #FFFFFF; }

table .total td {
  background: #909090;
  font-weight: bold;
  color: #FFFFFF; }

.align-center {
  text-align: center; }

.buttons-wrapper {
  display: flex;
  margin-top: 20px; }
  .buttons-wrapper > div {
    display: flex;
    flex-direction: column;
    margin-right: 10px; }

.hidden {
  display: none; }

.workflow_remarks {
  width: 100%;
  white-space: pre-wrap;
  margin-bottom: 20px;
  font-size: 14px; }

.workflow_history {
  width: 100%; }
  .workflow_history .table td:nth-child(2) {
    width: 30%; }

.print {
  position: absolute;
  right: 2.75rem;
  top: 2.25rem; }

.nav-container .nav {
  flex-wrap: nowrap; }

.accordion {
  margin-top: 1rem; }
  .accordion .card {
    background: none; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.button, .button:link, .button:visited {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #0088cc;
  font-size: 1.6rem;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 8px 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all .2s; }

.button:hover {
  background-color: #33bddf; }

.button:active {
  background-color: #022d41; }

.button:active, .button:focus {
  outline: none; }

.button__icon {
  margin-right: 5px; }
  .button__icon--no-margin {
    margin-right: 0; }

.button-transparent, .button-transparent:link, .button-transparent:visited {
  background-color: transparent;
  font-size: 1.6rem;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 3rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all .2s; }

.button-transparent:hover {
  background-color: #0088cc;
  color: #fff; }

.button-transparent:active, .button-transparent:focus {
  outline: none; }

.button-square, .button-square:link, .button-square:visited {
  width: 50px;
  height: 50px;
  background-color: transparent;
  font-size: 1.6rem;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all .2s; }

.button-square:hover {
  background-color: #0088cc;
  color: #fff; }

.button-square:active, .button-square:focus {
  outline: none; }

.button-white, .button-white:link, .button-white:visited {
  background-color: #fff;
  color: #022d41; }

.button-white:hover {
  background-color: #a3a3a3; }

.button-white:active, .button-white:focus {
  outline: none; }

.buttons {
  display: flex; }
  .buttons > div {
    margin-right: 10px; }
    .buttons > div:last-child {
      margin-right: 0; }

.button-disabled {
  opacity: 0.65;
  pointer-events: none; }

.card {
  background-color: #191c20;
  display: flex;
  width: 100%;
  min-width: 100%; }
  .card__body {
    padding: 2rem;
    flex: 1; }

.checkbox {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .checkbox__container {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex; }
    .checkbox__container--center {
      justify-content: center; }
  .checkbox__label {
    font-size: 1.4rem; }
    .checkbox__label--error {
      color: red; }
  .checkbox__icon-left {
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    border: none;
    background-color: #0d0d0d;
    color: #4b5564;
    border-radius: 4px 0 0 4px; }
  .checkbox__text {
    padding: 0.9rem 1.2rem;
    font-size: 1.6rem;
    background-color: #2a3038;
    border-radius: 4px;
    background-clip: padding-box;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1);
    outline: none;
    width: 100%; }
    .checkbox__text--icon-left {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .checkbox__text--icon-right {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .checkbox__text--no-border {
      border: 0; }
    .checkbox__text--disabled {
      color: rgba(0, 0, 0, 0.3);
      pointer-events: none; }
    .checkbox__text:active, .checkbox__text:focus {
      outline: none; }
  .checkbox__icon-right {
    padding: 1.2rem 1rem;
    font-size: 1.6rem;
    border: none;
    background-color: #0d0d0d;
    color: #4b5564;
    border-radius: 0 4px 4px 0; }
  .checkbox__err {
    font-size: 1.6rem;
    color: red; }
  .checkbox__no-margin {
    margin-bottom: 0; }
  .checkbox__content {
    position: relative;
    cursor: pointer;
    font-size: 1.6rem;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    margin-left: 0px; }
    .checkbox__content input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0; }
      .checkbox__content input:checked ~ .checkbox__checkmark {
        background-color: #022d41;
        display: flex;
        justify-content: center;
        align-self: center; }
      .checkbox__content input:checked ~ .checkbox__checkmark:after {
        display: flex;
        justify-content: center;
        align-items: center; }
    .checkbox__content:hover input ~ .checkbox__checkmark {
      background-color: #777; }
    .checkbox__content .checkbox__checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
  .checkbox__checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #999; }
  .checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none; }

.input {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 15px; }
  .input input[type=checkbox] {
    width: 20px; }
  .input__container {
    width: 100%;
    display: flex; }
    .input__container.total {
      justify-content: flex-end; }
  .input__label {
    font-size: 1.4rem; }
    .input__label--error {
      color: red; }
  .input__icon-left {
    order: 1;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .input__icon-left__default {
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      background-color: #fff;
      border-radius: 4px 0 0 4px; }
    .input__icon-left__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .input__text, .input__file, .input__datepicker {
    order: 2;
    padding: 0.9rem 1.2rem;
    height: 4rem;
    font-size: 1.6rem;
    background-clip: padding-box;
    outline: none;
    width: 100%; }
    .input__text::placeholder, .input__file::placeholder, .input__datepicker::placeholder {
      color: rgba(255, 255, 255, 0.5); }
    .input__text__default, .input__file__default, .input__datepicker__default {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3); }
      .input__text__default--icon-left, .input__file__default--icon-left, .input__datepicker__default--icon-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .input__text__default--icon-right, .input__file__default--icon-right, .input__datepicker__default--icon-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .input__text__line, .input__file__line, .input__datepicker__line {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .input__text--no-border, .input__file--no-border, .input__datepicker--no-border {
      border: 0; }
    .input__text--disabled, .input__file--disabled, .input__datepicker--disabled {
      color: rgba(0, 0, 0, 0.3);
      pointer-events: none; }
    .input__text:active, .input__text:focus, .input__file:active, .input__file:focus, .input__datepicker:active, .input__datepicker:focus {
      outline: none; }
    .input__text .react-date-picker__wrapper, .input__file .react-date-picker__wrapper, .input__datepicker .react-date-picker__wrapper {
      border: 0;
      line-height: 20px; }
  .input__file {
    min-width: 270px;
    border: none; }
  .input__icon-right {
    order: 3;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .input__icon-right__default {
      border: none;
      border-radius: 4px 0 0 4px; }
    .input__icon-right__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .input__err {
    font-size: 1.6rem;
    color: red; }
  .input__no-margin {
    margin-bottom: 0; }
  .input input:-webkit-autofill,
  .input input:-webkit-autofill:hover,
  .input input:-webkit-autofill:focus,
  .input input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important; }
  .input input:-webkit-autofill {
    -webkit-text-fill-color: #000 !important; }
  .input__text, .input textarea {
    transition: border 0.30s ease-in-out; }
  .input__text__default:focus {
    border: 1px solid #000; }
  .input__text__line:focus {
    border-bottom: 1px solid #fff; }
  .input__text__line:focus ~ .input__icon-left__line {
    border-bottom: 1px solid #fff; }
  .input__text__line:focus ~ .input__icon-right__line {
    border-bottom: 1px solid #fff; }
  .input__preview {
    font-size: 1.6rem; }
    .input__preview.editable {
      line-height: 40px; }
  .input .file_input {
    flex-grow: 1; }
  .input .input_file__remove {
    line-height: 40px; }

.textarea {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .textarea__container {
    width: 100%;
    display: flex; }
  .textarea__label {
    font-size: 1.4rem; }
    .textarea__label--error {
      color: red; }
  .textarea__icon-left {
    order: 1;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .textarea__icon-left__default {
      border: none;
      background-color: #fff;
      border-radius: 4px 0 0 4px; }
    .textarea__icon-left__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .textarea__text {
    order: 2;
    padding: 0.9rem 1.2rem;
    font-size: 1.6rem;
    background-clip: padding-box;
    outline: none;
    width: 100%; }
    .textarea__text::placeholder {
      color: rgba(255, 255, 255, 0.5); }
    .textarea__text__default {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3); }
      .textarea__text__default--icon-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .textarea__text__default--icon-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .textarea__text__line {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .textarea__text--no-border {
      border: 0; }
    .textarea__text--disabled {
      color: rgba(0, 0, 0, 0.3);
      pointer-events: none; }
    .textarea__text:active, .textarea__text:focus {
      outline: none; }
  .textarea__icon-right {
    order: 3;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .textarea__icon-right__default {
      border: none;
      border-radius: 4px 0 0 4px; }
    .textarea__icon-right__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .textarea__err {
    font-size: 1.6rem;
    color: red; }
  .textarea__no-margin {
    margin-bottom: 0; }
  .textarea textarea:-webkit-autofill,
  .textarea textarea:-webkit-autofill:hover,
  .textarea textarea:-webkit-autofill:focus,
  .textarea textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important; }
  .textarea textarea:-webkit-autofill {
    -webkit-text-fill-color: #000 !important; }
  .textarea__text, .textarea textarea {
    transition: border 0.30s ease-in-out; }
  .textarea__text__default:focus {
    border: 1px solid #000; }
  .textarea__text__line:focus {
    border-bottom: 1px solid #fff; }
  .textarea__text__line:focus ~ .textarea__icon-left__line {
    border-bottom: 1px solid #fff; }
  .textarea__text__line:focus ~ .textarea__icon-right__line {
    border-bottom: 1px solid #fff; }
  .textarea__text.remarks {
    min-height: 300px; }
  .textarea__preview {
    font-size: 1.6rem;
    word-break: break-word;
    hyphens: auto;
    white-space: pre-wrap; }

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999999; }
  .loading__full {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .loading__message {
    color: #000;
    font-size: 3.6rem;
    font-weight: bold; }

.loading-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }
  .loading-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0); }
    .loading-ellipsis div:nth-child(1) {
      left: 6px;
      animation: lds-ellipsis1 0.6s infinite; }
    .loading-ellipsis div:nth-child(2) {
      left: 6px;
      animation: lds-ellipsis2 0.6s infinite; }
    .loading-ellipsis div:nth-child(3) {
      left: 26px;
      animation: lds-ellipsis2 0.6s infinite; }
    .loading-ellipsis div:nth-child(4) {
      left: 45px;
      animation: lds-ellipsis3 0.6s infinite; }
  .loading-ellipsis--white div {
    background: #fff; }

.loading-hide {
  display: none; }

.loading-show {
  display: flex; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(19px, 0); } }

.swal2-container {
  width: auto; }
  .swal2-container .swal2-popup {
    width: auto; }

[class^=swal2] {
  width: auto; }

.modal {
  visibility: hidden;
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  .modal__overlay {
    z-index: 998;
    visibility: hidden;
    position: fixed;
    background-color: #000;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .15s linear; }
    .modal__overlay--show {
      visibility: visible;
      background-color: #000;
      opacity: .5; }
  .modal__button-container {
    width: 100%; }
  .modal__button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #0088cc;
    font-size: 1.6rem;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.2rem 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all .2s; }
    .modal__button:hover {
      background-color: #33bddf; }
    .modal__button:active {
      background-color: #022d41; }
    .modal__button:active, .modal__button:focus {
      outline: none; }
  .modal__content {
    position: relative;
    background-color: #2e2f32;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    opacity: 0;
    transition: all .15s linear; }
    .modal__content--show {
      visibility: visible;
      background-color: #000;
      opacity: 1; }
  .modal__header {
    padding: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7); }
  .modal__body {
    padding: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7); }
  .modal__footer {
    padding: 2rem; }
  .modal__close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold; }
    .modal__close:hover, .modal__close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer; }

.pagination {
  display: inline-block;
  background-color: #fff; }

.pagination button {
  color: #000;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #000; }

.pagination button.active {
  background-color: #0088cc;
  color: #fff;
  border: 1px solid #0088cc; }

.pagination button:hover:not(.active) {
  background-color: #ddd; }

.pagination button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.pagination button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.select {
  width: 100%;
  margin-bottom: 1rem; }
  .select__no-margin {
    margin-bottom: 0; }
  .select__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .select__label {
    font-size: 1.4rem; }
    .select__label--error {
      color: red; }
  .select__input {
    padding: 0.9rem 1.2rem;
    font-size: 1.6rem;
    background-color: #fff;
    border-radius: 4px;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-appearance: button;
    -webkit-user-select: none; }
    .select__input--center {
      text-align-last: center; }
    .select__input:active, .select__input:focus {
      outline: none; }
    .select__input option {
      color: #022d41; }
      .select__input option:disabled {
        cursor: none;
        color: #CCCCCC; }
    .select__input:disabled {
      color: rgba(0, 0, 0, 0.3);
      background: #f0f0f0; }
  .select__arrow {
    color: #000;
    font-size: 1.6rem; }
  .select__err {
    font-size: 1.6rem;
    color: red; }
  .select__placeholder {
    color: rgba(0, 0, 0, 0.5); }

.search-select {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .search-select__container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .search-select__label {
    font-size: 1.4rem; }
    .search-select__label--error {
      color: red; }
  .search-select__icon-left {
    order: 1;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .search-select__icon-left__default {
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: #fff;
      border-radius: 4px 0 0 4px;
      border-right: 0; }
    .search-select__icon-left__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .search-select__text {
    order: 2;
    padding: 0.9rem 1.2rem;
    height: 4rem;
    font-size: 1.6rem;
    background-clip: padding-box;
    outline: none;
    width: 100%; }
    .search-select__text::placeholder {
      color: rgba(0, 0, 0, 0.5); }
    .search-select__text__default {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3); }
      .search-select__text__default--icon-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .search-select__text__default--icon-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .search-select__text__line {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .search-select__text--no-border {
      border: 0; }
    .search-select__text--disabled {
      color: rgba(0, 0, 0, 0.3);
      pointer-events: none; }
    .search-select__text:focus + .search-select__list {
      display: block; }
    .search-select__text:active, .search-select__text:focus {
      outline: none; }
  .search-select__icon-right {
    order: 3;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .search-select__icon-right__default {
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 0 4px 4px 0;
      border-left: 0; }
    .search-select__icon-right__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .search-select__err {
    font-size: 1.6rem;
    color: red; }
  .search-select__no-margin {
    margin-bottom: 0; }
  .search-select__clear {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    font-size: 1.6rem; }
  .search-select__list {
    display: none;
    max-height: 30rem;
    width: 50rem;
    overflow: auto;
    position: absolute;
    top: 4.4rem;
    left: 0;
    right: 0;
    background-color: #fff;
    color: #000;
    z-index: 1;
    border: 1px solid #000;
    border-radius: 4px; }
    @media only screen and (max-width: 56.25em) {
      .search-select__list {
        max-height: 20rem; } }
  .search-select__item {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 1.6rem;
    color: #000;
    padding: 10px; }
  .search-select__disabled {
    pointer-events: none; }
  .search-select__text-disabled {
    color: rgba(0, 0, 0, 0.3); }
  .search-select__icon-disabled {
    color: rgba(0, 0, 0, 0.3); }
  .search-select__placeholder::placeholder {
    color: #000; }
  .search-select__placeholder-disabled::placeholder {
    color: rgba(0, 0, 0, 0.3); }
  .search-select input:-webkit-autofill,
  .search-select input:-webkit-autofill:hover,
  .search-select input:-webkit-autofill:focus,
  .search-select input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important; }
  .search-select input:-webkit-autofill {
    -webkit-text-fill-color: #000 !important; }
  .search-select__text, .search-select textarea {
    transition: border 0.30s ease-in-out;
    text-overflow: ellipsis; }
  .search-select__text__default:focus {
    border: 1px solid #000; }
  .search-select__text__default:focus ~ .search-select__icon-left__default {
    border-left-color: #000;
    border-top-color: #000;
    border-bottom-color: #000; }
  .search-select__text__default:focus ~ .search-select__icon-right__default {
    border-right-color: #000;
    border-top-color: #000;
    border-bottom-color: #000; }
  .search-select__text__line:focus {
    border-bottom: 1px solid #fff; }
  .search-select__text__line:focus ~ .input__icon-left__line {
    border-bottom: 1px solid #fff; }
  .search-select__text__line:focus ~ .input__icon-right__line {
    border-bottom: 1px solid #fff; }
  .search-select__title, .search-select__title:link, .search-select__title:visited {
    width: 100%;
    text-align: left;
    color: #000;
    cursor: pointer;
    transition: all .2s; }
  .search-select__title:hover {
    background-color: #dddede; }
  .search-select__title:active {
    background-color: #022d41; }
  .search-select__title:active, .search-select__title:focus {
    outline: none; }
  .search-select__title p {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5); }
  .search-select__category, .search-select__category:link, .search-select__category:visited {
    width: 100%;
    text-align: left;
    color: #000;
    cursor: pointer;
    transition: all .2s; }
  .search-select__category:hover {
    background-color: #dddede; }
  .search-select__category:active {
    background-color: #022d41; }
  .search-select__category:active, .search-select__category:focus {
    outline: none; }
  .search-select__category p {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding-left: 4rem; }
  .search-select__group, .search-select__group:link, .search-select__group:visited {
    width: 100%;
    text-align: left;
    color: #000;
    cursor: pointer;
    transition: all .2s; }
  .search-select__group:hover {
    background-color: #dddede; }
  .search-select__group:active {
    background-color: #022d41; }
  .search-select__group:active, .search-select__group:focus {
    outline: none; }
  .search-select__group p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding: 1rem;
    padding-left: 6rem; }
  .search-select__account, .search-select__account:link, .search-select__account:visited {
    width: 100%;
    text-align: left;
    color: #000;
    cursor: pointer;
    transition: all .2s; }
  .search-select__account:hover {
    background-color: #dddede; }
  .search-select__account:active {
    background-color: #022d41; }
  .search-select__account:active, .search-select__account:focus {
    outline: none; }
  .search-select__account p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding: 1rem;
    padding-left: 8rem; }

.table {
  border: 3px solid;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .table td, .table th {
    border: 1px solid #000;
    padding: 8px; }
  .table tr:nth-child(1) td:nth-child(1), .table tr:nth-child(1) th:nth-child(1) {
    width: 2%; }
  .table.no-index tr:nth-child(1) td:nth-child(1), .table.no-index tr:nth-child(1) th:nth-child(1) {
    width: auto; }
  .table tr.error td {
    background-color: #FFBABA; }
  .table td {
    font-size: 1.6rem; }
    .table td.nominal {
      text-align: right;
      width: 150px;
      min-width: 150px;
      max-width: 150px; }
      .table td.nominal input, .table td.nominal .input__container, .table td.nominal .input__preview {
        text-align: right; }
      .table td.nominal .input__preview {
        margin-bottom: 0; }
        .table td.nominal .input__preview p {
          display: block;
          width: 100%; }
      .table td.nominal.red {
        color: red; }
    .table td.persentase, .table td.nominal.persentase {
      text-align: right;
      min-width: 80px; }
    .table td.file a {
      cursor: pointer; }
      .table td.file a:hover {
        text-decoration: underline; }
  .table tr.total td {
    color: #000000; }
  .table tr.total.summary td, .table tr.total.summary td.col_rekomendasi {
    background-color: #AFEEEE;
    font-weight: bold; }
  .table tr.total td {
    background-color: #DEDEDE; }
  .table tfoot tr.total td {
    background-color: #AFEEEE; }
  .table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center; }
  .table__actions {
    display: flex; }
    .table__actions button {
      margin-right: 1rem; }
      .table__actions button:last-child {
        margin-right: 0; }
  .table .border_double {
    border-right: 2px #000000 solid;
    border-left: 2px #000000 solid; }
    .table .border_double.border_double__left {
      border-right: 1px solid; }
    .table .border_double.border_double__right {
      border-left: 1px solid; }
  .table .sub_header {
    border-top: 3px solid;
    border-bottom: 3px solid; }
  .table_title h1, .table_title h2, .table_title h3 {
    text-align: center; }

.table-filters {
  display: flex; }
  .table-filters > * {
    margin-right: 1rem; }
  .table-filters > *:last-child {
    margin-right: 0; }

.option {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .option__container {
    width: 100%;
    display: flex; }
  .option__label {
    font-size: 1.4rem; }
    .option__label--error {
      color: red; }
  .option__select {
    font-size: 3.6rem; }
  .option__list-label {
    margin-right: 1rem;
    margin-left: 2px; }
  .option__preview {
    font-size: 1.6rem; }

.notifications-wrapper {
  position: absolute;
  width: 400px;
  max-height: 500px;
  overflow: hidden;
  left: -240px;
  top: 45px;
  background: #FFFFFF; }

.notifications-content {
  position: relative;
  overflow-y: scroll;
  max-width: 400px;
  max-height: 500px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: 100%; }

.notifications-item {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 100%;
  border-bottom: 1px solid #CCCCCC;
  padding: 10px; }

.notifications-subject {
  font-size: 14px;
  width: 100%;
  color: #999999; }

.notifications-wrapper {
  position: absolute;
  width: 400px;
  max-height: 500px;
  overflow: hidden;
  left: -240px;
  top: 45px;
  background: #FFFFFF; }

.notifications-content {
  position: relative;
  overflow-y: scroll;
  max-width: 400px;
  max-height: 500px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: 100%; }

.notifications-item {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 100%;
  border-bottom: 1px solid #CCCCCC;
  padding: 10px; }

.notifications-subject {
  font-size: 14px;
  width: 100%;
  color: #999999; }

.admission-header {
  position: fixed;
  width: 100%;
  height: 17rem;
  background-image: url("../img/background-merge.png");
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem; }
  .admission-header__top {
    display: flex;
    height: 7rem;
    justify-content: space-between;
    align-items: flex-start; }
  .admission-header__title {
    position: absolute;
    right: 2rem;
    left: 2rem;
    top: calc(17rem - 7rem);
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .admission-header__title p:nth-child(1) {
      font-size: 2.4rem; }
    .admission-header__title p:nth-child(2) {
      font-size: 3.6rem;
      font-weight: bold; }
  .admission-header__logo {
    width: 200px;
    height: auto; }
  .admission-header__top-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .admission-header__search-form {
    flex: 1;
    margin-right: 2rem; }
  .admission-header__icon {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: 1.6rem; }
  .admission-header__ypl-logo {
    margin-right: 2rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%; }
  .admission-header__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 2rem; }
  .admission-header__bottom-content {
    flex: 1;
    padding: 0 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.3); }
    .admission-header__bottom-content__content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center; }
  .admission-header__greeting {
    color: #fff;
    font-size: 2.4rem; }
  .admission-header__logout {
    color: #fff;
    padding: 1.2rem 1rem; }

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0; }
  .breadcrumb ol {
    list-style-type: none; }
  .breadcrumb__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.6rem; }
  .breadcrumb__item {
    display: flex;
    color: #fff; }
    .breadcrumb__item:not(:first-child)::before {
      padding: 0 1rem;
      color: #fff;
      content: "/"; }
    .breadcrumb__item:last-child {
      pointer-events: none; }
    .breadcrumb__item:not(:last-child) {
      color: #007bff; }

.collapsible-menu__list {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden; }
  .collapsible-menu__list--show {
    max-height: 1000px;
    transition: max-height 0.25s ease-in; }

.collapsible-menu__child-container {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.collapsible-menu__child-menu {
  color: #a3a3a3;
  padding: 0.8rem 1.6rem;
  font-size: 1.5rem;
  line-height: 1; }
  .collapsible-menu__child-menu:hover {
    color: #fff; }

.content-table__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .content-table__header {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; } }

.content-table__entries {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 56.25em) {
    .content-table__entries {
      margin-bottom: 2rem; } }

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: bottom; }
  .form-group-member {
    margin-right: 2rem;
    align-self: flex-end; }
    .form-group-member:last-child {
      margin-right: 0; }
    .form-group-member > * {
      margin-bottom: 0; }
    .form-group-member button {
      margin-bottom: 3px; }
  .form-group.inline {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 100%;
    margin-right: 0; }
    .form-group.inline .form-group-member {
      flex-direction: column;
      flex-grow: 1; }
      .form-group.inline .form-group-member::first {
        margin-right: 2rem; }
      .form-group.inline .form-group-member .form-group.inline {
        flex: 1 0 auto; }
    .form-group.inline .form-group-label {
      flex: 0 1 100%; }

.header {
  position: fixed;
  width: 100%;
  height: 17rem;
  background-image: url("../img/background-merge.png");
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem; }
  .header__top {
    display: flex;
    height: 7rem;
    justify-content: space-between;
    align-items: flex-start; }
  .header__top-left {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 7rem;
    width: 31rem; }
    @media only screen and (max-width: 56.25em) {
      .header__top-left {
        width: 12rem; } }
  .header__title {
    position: absolute;
    right: 2rem;
    left: 2rem;
    top: calc(17rem - 7rem);
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .header__title p:nth-child(1) {
      font-size: 2.4rem; }
    .header__title p:nth-child(2) {
      font-size: 3.6rem;
      font-weight: bold; }
  .header__logo {
    width: 200px;
    height: auto; }
  .header__top-right {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
  .header__search-form {
    flex: 1;
    margin-right: 2rem; }
  .header__icon {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: 1.6rem;
    position: relative; }
    .header__icon > span {
      position: absolute;
      top: 13px;
      left: 12px; }
  .header__ypl-logo {
    margin-right: 2rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%; }
  .header__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  .header__bottom-right {
    flex: 1;
    padding: 0 2rem;
    margin-right: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.3); }
    .header__bottom-right__content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center; }
  .header__greeting {
    color: #fff;
    font-size: 2.4rem; }
  .header__logout {
    color: #fff;
    padding: 1.2rem 1rem; }

@keyframes pulsar {
  0% {
    transform: scale(0);
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 0; } }

@-webkit-keyframes pulsar {
  0% {
    -webkit-transform: scale(0);
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

.pulsar {
  width: 48px;
  height: 48px;
  margin: -4px 0 0 -4px;
  position: relative; }

.pulsar .ring {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  border: 5px solid rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 100px 0 rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin-top: -50%;
  margin-left: -50%;
  -webkit-transform: scale(0);
  transform: scale(0); }

.pulsar .ring:nth-child(1) {
  -webkit-animation: pulsar 2s 0s linear infinite;
  animation: pulsar 2s 0s linear infinite; }

.pulsar .ring:nth-child(2) {
  -webkit-animation: pulsar 2s .5s linear infinite;
  animation: pulsar 2s .5s linear infinite; }

.pulsar .ring:nth-child(3) {
  -webkit-animation: pulsar 2s 1s linear infinite;
  animation: pulsar 2s 1s linear infinite; }

.pulsar .ring:nth-child(4) {
  -webkit-animation: pulsar 2s 1.5s linear infinite;
  animation: pulsar 2s 1.5s linear infinite; }

.list-form__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.list-form__content {
  display: flex;
  width: calc(50% - 1rem);
  flex-direction: row;
  margin-right: 2rem; }
  .list-form__content--full {
    display: flex;
    width: 100%;
    flex-direction: row; }
  .list-form__content:nth-child(even) {
    margin-right: 0; }
  .list-form__content .input__disabled > input {
    color: rgba(0, 0, 0, 0.3);
    background: #f0f0f0; }
  .list-form__content .input__disabled .react-date-picker input, .list-form__content .input__disabled .react-date-picker span {
    color: rgba(0, 0, 0, 0.3); }

.list-form__date-of-birth > div:nth-child(2) {
  margin-left: 1rem; }

.list-form__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .list-form__footer div:nth-child(1) {
    margin-right: 2rem; }

.list-form__title {
  min-height: 4rem;
  background-clip: padding-box;
  outline: none;
  width: 100%;
  font-weight: bold;
  font-size: 2.4rem; }

.list-form2__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.list-form2__content {
  display: flex;
  width: calc(50% - 1rem);
  flex-direction: row;
  margin-right: 2rem; }
  .list-form2__content--full {
    display: flex;
    width: 100%;
    flex-direction: row; }
  .list-form2__content:nth-child(even) {
    margin-right: 0; }
  .list-form2__content .input__disabled input {
    color: #FFFFFF;
    background: #9e9e9e; }

.list-form2__date-of-birth > div:nth-child(2) {
  margin-left: 1rem; }

.list-form2__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .list-form2__footer div:nth-child(1) {
    margin-right: 2rem; }

.list-form2__title {
  min-height: 4rem;
  background-clip: padding-box;
  outline: none;
  width: 100%;
  font-weight: bold;
  font-size: 2.4rem; }

.menu {
  font-size: 1.5rem;
  width: 100%;
  padding: 1rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(119, 119, 119, 0.3);
  font-family: 'Poppins', sans-serif; }
  .menu__left {
    display: flex;
    justify-content: center;
    align-items: center; }
  .menu__left-icon {
    font-size: 3.6rem;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center; }
    .menu__left-icon img {
      width: 30px;
      height: 30px; }
    .menu__left-icon--small {
      font-size: 1.4rem; }
  .menu__title {
    margin-left: 1.9rem; }

.sidebar {
  position: fixed;
  width: 31rem;
  background-color: #ccc;
  min-height: 100%;
  z-index: 1; }
  .sidebar__wrapper {
    position: relative;
    display: flex;
    width: 31rem;
    flex-direction: column; }
    .sidebar__wrapper.collapsed {
      transition: 1s;
      left: 0rem;
      width: 7rem; }
      .sidebar__wrapper.collapsed .sidebar {
        transition: 1s;
        width: 7rem; }
        .sidebar__wrapper.collapsed .sidebar__menu-container {
          overflow: hidden; }
          .sidebar__wrapper.collapsed .sidebar__menu-container .collapsible-menu__list {
            display: none; }
          .sidebar__wrapper.collapsed .sidebar__menu-container__collapsed {
            display: none; }
    .sidebar__wrapper.expanded {
      transition: 1s;
      left: 0;
      width: 31rem; }
      .sidebar__wrapper.expanded .sidebar {
        transition: 1s;
        width: 31rem; }
        .sidebar__wrapper.expanded .sidebar__menu-container {
          overflow: auto; }
          .sidebar__wrapper.expanded .sidebar__menu-container__collapsed {
            display: none; }
  .sidebar__menu-container {
    max-height: calc(100vh - 17rem);
    padding-top: 6rem;
    overflow: auto;
    position: relative; }
  .sidebar__user-dropdown {
    width: 100%;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .sidebar__user-dropdown--left {
      display: flex;
      justify-content: center;
      align-items: center; }
  .sidebar__username {
    margin-left: 2rem; }
  .sidebar__menu {
    padding: 0 2rem; }
    .sidebar__menu:last-child {
      margin-bottom: 4rem; }
  .sidebar .navbar_toggle_button {
    position: absolute;
    top: 50%;
    margin-top: -9rem;
    background: white;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    right: -1.5rem;
    z-index: 2;
    box-shadow: 0 0 0.6rem; }
    .sidebar .navbar_toggle_button:hover {
      box-shadow: 0 0 0.8rem; }
      .sidebar .navbar_toggle_button:hover .navbar_toggle_content {
        color: #000000; }
    .sidebar .navbar_toggle_button .navbar_toggle_content {
      font-size: 2rem;
      font-weight: bold;
      color: #757575;
      display: block;
      line-height: 4rem;
      width: 4rem;
      height: 4rem; }

.select-coa {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .select-coa__container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .select-coa__label {
    font-size: 1.4rem; }
    .select-coa__label--error {
      color: red; }
  .select-coa__icon-left {
    order: 1;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .select-coa__icon-left__default {
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: #fff;
      border-radius: 4px 0 0 4px;
      border-right: 0; }
    .select-coa__icon-left__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .select-coa__text {
    order: 2;
    padding: 0.9rem 1.2rem;
    height: 4rem;
    font-size: 1.6rem;
    background-clip: padding-box;
    outline: none;
    width: 100%; }
    .select-coa__text::placeholder {
      color: rgba(0, 0, 0, 0.5); }
    .select-coa__text__default {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3); }
      .select-coa__text__default--icon-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .select-coa__text__default--icon-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .select-coa__text__line {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .select-coa__text--no-border {
      border: 0; }
    .select-coa__text--disabled {
      color: rgba(0, 0, 0, 0.3);
      background: transparent !important;
      cursor: not-allowed !important; }
    .select-coa__text:focus + .select-coa__list {
      display: block; }
    .select-coa__text:active, .select-coa__text:focus {
      outline: none; }
  .select-coa__icon-right {
    order: 3;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .select-coa__icon-right__default {
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 0 4px 4px 0;
      border-left: 0; }
    .select-coa__icon-right__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .select-coa__err {
    font-size: 1.6rem;
    color: red; }
  .select-coa__no-margin {
    margin-bottom: 0; }
  .select-coa__clear {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    font-size: 1.6rem; }
  .select-coa__list {
    display: none;
    max-height: 30rem;
    width: 50rem;
    overflow: auto;
    position: absolute;
    top: 4.4rem;
    left: 0;
    right: 0;
    background-color: #fff;
    color: #000;
    z-index: 1;
    border: 1px solid #000;
    border-radius: 4px; }
    @media only screen and (max-width: 56.25em) {
      .select-coa__list {
        max-height: 20rem; } }
  .select-coa__item {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 1.6rem;
    color: #000;
    padding: 10px; }
  .select-coa__disabled {
    pointer-events: none; }
  .select-coa__text-disabled {
    color: rgba(0, 0, 0, 0.3); }
  .select-coa__icon-disabled {
    color: rgba(0, 0, 0, 0.3); }
  .select-coa__placeholder::placeholder {
    color: #000; }
  .select-coa__placeholder-disabled::placeholder {
    color: rgba(0, 0, 0, 0.3); }
  .select-coa input:-webkit-autofill,
  .select-coa input:-webkit-autofill:hover,
  .select-coa input:-webkit-autofill:focus,
  .select-coa input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important; }
  .select-coa input:-webkit-autofill {
    -webkit-text-fill-color: #000 !important; }
  .select-coa__text, .select-coa textarea {
    transition: border 0.30s ease-in-out; }
  .select-coa__text__default:focus {
    border: 1px solid #000; }
  .select-coa__text__default:focus ~ .select-coa__icon-left__default {
    border-left-color: #000;
    border-top-color: #000;
    border-bottom-color: #000; }
  .select-coa__text__default:focus ~ .select-coa__icon-right__default {
    border-right-color: #000;
    border-top-color: #000;
    border-bottom-color: #000; }
  .select-coa__text__line:focus {
    border-bottom: 1px solid #fff; }
  .select-coa__text__line:focus ~ .input__icon-left__line {
    border-bottom: 1px solid #fff; }
  .select-coa__text__line:focus ~ .input__icon-right__line {
    border-bottom: 1px solid #fff; }
  .select-coa__title, .select-coa__title:link, .select-coa__title:visited {
    width: 100%;
    text-align: left;
    color: #000;
    cursor: pointer;
    transition: all .2s; }
  .select-coa__title:hover {
    background-color: #dddede; }
  .select-coa__title:active {
    background-color: #022d41; }
  .select-coa__title:active, .select-coa__title:focus {
    outline: none; }
  .select-coa__title p {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5); }
  .select-coa__category, .select-coa__category:link, .select-coa__category:visited {
    width: 100%;
    text-align: left;
    color: #000;
    cursor: pointer;
    transition: all .2s; }
  .select-coa__category:hover {
    background-color: #dddede; }
  .select-coa__category:active {
    background-color: #022d41; }
  .select-coa__category:active, .select-coa__category:focus {
    outline: none; }
  .select-coa__category p {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding-left: 4rem; }
  .select-coa__group, .select-coa__group:link, .select-coa__group:visited {
    width: 100%;
    text-align: left;
    color: #000;
    cursor: pointer;
    transition: all .2s; }
  .select-coa__group:hover {
    background-color: #dddede; }
  .select-coa__group:active {
    background-color: #022d41; }
  .select-coa__group:active, .select-coa__group:focus {
    outline: none; }
  .select-coa__group p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding: 1rem;
    padding-left: 6rem; }
  .select-coa__account, .select-coa__account:link, .select-coa__account:visited {
    width: 100%;
    text-align: left;
    color: #000;
    cursor: pointer;
    transition: all .2s; }
  .select-coa__account:hover {
    background-color: #dddede; }
  .select-coa__account:active {
    background-color: #022d41; }
  .select-coa__account:active, .select-coa__account:focus {
    outline: none; }
  .select-coa__account p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding: 1rem;
    padding-left: 8rem; }

.salary-option {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .salary-option__container {
    width: 100%;
    display: flex; }
  .salary-option__label {
    font-size: 1.4rem; }
    .salary-option__label--error {
      color: red; }
  .salary-option__icon-left {
    order: 1;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .salary-option__icon-left__default {
      border: none;
      background-color: #fff;
      border-radius: 4px 0 0 4px; }
    .salary-option__icon-left__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .salary-option__text {
    order: 2;
    padding: 0.9rem 1.2rem;
    height: 4rem;
    font-size: 1.6rem;
    background-clip: padding-box;
    outline: none;
    width: 100%; }
    .salary-option__text::placeholder {
      color: rgba(255, 255, 255, 0.5); }
    .salary-option__text__default {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3); }
      .salary-option__text__default--icon-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .salary-option__text__default--icon-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .salary-option__text__line {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .salary-option__text--no-border {
      border: 0; }
    .salary-option__text--disabled {
      color: rgba(0, 0, 0, 0.3);
      pointer-events: none; }
    .salary-option__text:active, .salary-option__text:focus {
      outline: none; }
  .salary-option__icon-right {
    order: 3;
    height: 4rem;
    padding: 1.2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    transition: border 0.30s ease-in-out; }
    .salary-option__icon-right__default {
      border: none;
      border-radius: 4px 0 0 4px; }
    .salary-option__icon-right__line {
      color: #fff;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .salary-option__err {
    font-size: 1.6rem;
    color: red; }
  .salary-option__no-margin {
    margin-bottom: 0; }
  .salary-option input:-webkit-autofill,
  .salary-option input:-webkit-autofill:hover,
  .salary-option input:-webkit-autofill:focus,
  .salary-option input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important; }
  .salary-option input:-webkit-autofill {
    -webkit-text-fill-color: #000 !important; }
  .salary-option__text, .salary-option textarea {
    transition: border 0.30s ease-in-out; }
  .salary-option__text__default:focus {
    border: 1px solid #000; }
  .salary-option__text__line:focus {
    border-bottom: 1px solid #fff; }
  .salary-option__text__line:focus ~ .salary-option__icon-left__line {
    border-bottom: 1px solid #fff; }
  .salary-option__text__line:focus ~ .salary-option__icon-right__line {
    border-bottom: 1px solid #fff; }
  .salary-option__preview {
    font-size: 1.6rem; }

.book-content {
  display: flex;
  flex-direction: row;
  flex: 1; }
  .book-content__wrapper-left {
    display: flex;
    flex: 8;
    flex-direction: column; }
  .book-content__wrapper-right {
    display: flex;
    flex: 2; }
  .book-content__content {
    display: flex;
    width: calc(50% - 1rem);
    flex-direction: row;
    margin-right: 2rem; }
    .book-content__content--full {
      display: flex;
      width: 100%;
      flex-direction: row; }
    .book-content__content:nth-child(even) {
      margin-right: 0; }
  .book-content__content-wrapper {
    width: 100%; }
  .book-content__student {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .book-content__detail-wrapper {
    width: 100%; }
  .book-content__detail {
    display: flex;
    flex: 1;
    flex-direction: row; }
    .book-content__detail div:nth-child(1) {
      display: flex;
      flex: 5; }
    .book-content__detail div:nth-child(2) {
      display: flex;
      flex: 5; }
    .book-content__detail div p {
      word-break: break-all;
      hyphens: auto;
      white-space: pre-wrap; }

.report-card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .report-card__header {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; } }

.report-card__entries {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-basis: 100%; }
  @media only screen and (max-width: 56.25em) {
    .report-card__entries {
      margin-bottom: 2rem; } }

.alert {
  font-size: medium; }
  .alert .button {
    line-height: 25px;
    float: right; }

.admission-route {
  overflow: hidden; }
  .admission-route__banner {
    position: absolute;
    z-index: 2; }
    .admission-route__banner img {
      display: block;
      width: 100%;
      height: 17rem;
      object-fit: cover; }
  .admission-route__wrapper {
    padding-top: 17rem;
    min-height: calc(100vh - 17rem);
    display: flex;
    flex-direction: row; }
  .admission-route__main-panel {
    background-color: #fff;
    width: 100%;
    min-height: calc(100vh - 17rem);
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 56.25em) {
      .admission-route__main-panel {
        margin-left: 0;
        width: 100%; } }
  .admission-route__content-wrapper {
    z-index: 3;
    background: rgba(255, 255, 255, 0.3);
    padding: 2.75rem 2.25rem;
    width: 100%;
    flex-grow: 1; }

.dashboard {
  overflow: hidden; }
  .dashboard__banner {
    position: absolute;
    z-index: 2; }
    .dashboard__banner img {
      display: block;
      width: 100%;
      height: 17rem;
      object-fit: cover; }
  .dashboard__wrapper {
    padding-top: 17rem;
    min-height: calc(100vh - 17rem);
    display: flex;
    flex-direction: row;
    position: relative; }
  .dashboard__main-panel {
    background-color: #fff;
    width: calc(100vh - 31rem);
    height: calc(100vh - 17rem);
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    @media only screen and (max-width: 56.25em) {
      .dashboard__main-panel {
        margin-left: 0;
        width: 100%; } }
  .dashboard__content-wrapper {
    z-index: 3;
    background: rgba(255, 255, 255, 0.3);
    padding: 2.75rem 2.25rem;
    width: 100%;
    flex: 1 1 auto;
    overflow: auto;
    position: relative; }
  .dashboard .unit_selector {
    position: absolute;
    margin-top: 5px;
    right: 40px; }
    .dashboard .unit_selector div.search-select__list {
      right: 0;
      left: auto; }
    .dashboard .unit_selector input.search-select__text {
      text-overflow: ellipsis;
      padding-right: 2rem; }
    .dashboard .unit_selector .search-select__clear {
      right: 1rem; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?yw8t07");
  src: url("../fonts/icomoon.eot?yw8t07#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?yw8t07") format("truetype"), url("../fonts/icomoon.woff?yw8t07") format("woff"), url("../fonts/icomoon.svg?yw8t07#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-plus:before {
  content: "\f067"; }

.icon-question:before {
  content: "\f128"; }

.icon-minus:before {
  content: "\f068"; }

.icon-music:before {
  content: "\f001"; }

.icon-search:before {
  content: "\f002"; }

.icon-heart:before {
  content: "\f004"; }

.icon-star:before {
  content: "\f005"; }

.icon-star-o:before {
  content: "\f006"; }

.icon-user:before {
  content: "\f007"; }

.icon-film:before {
  content: "\f008"; }

.icon-th:before {
  content: "\f00a"; }

.icon-th-list:before {
  content: "\f00b"; }

.icon-check:before {
  content: "\f00c"; }

.icon-close:before {
  content: "\f00d"; }

.icon-remove:before {
  content: "\f00d"; }

.icon-times:before {
  content: "\f00d"; }

.icon-search-plus:before {
  content: "\f00e"; }

.icon-search-minus:before {
  content: "\f010"; }

.icon-power-off:before {
  content: "\f011"; }

.icon-cog:before {
  content: "\f013"; }

.icon-gear:before {
  content: "\f013"; }

.icon-trash-o:before {
  content: "\f014"; }

.icon-home:before {
  content: "\f015"; }

.icon-file-o:before {
  content: "\f016"; }

.icon-clock-o:before {
  content: "\f017"; }

.icon-download:before {
  content: "\f019"; }

.icon-play-circle-o:before {
  content: "\f01d"; }

.icon-repeat:before {
  content: "\f01e"; }

.icon-rotate-right:before {
  content: "\f01e"; }

.icon-refresh:before {
  content: "\f021"; }

.icon-lock:before {
  content: "\f023"; }

.icon-bookmark:before {
  content: "\f02e"; }

.icon-camera:before {
  content: "\f030"; }

.icon-image:before {
  content: "\f03e"; }

.icon-photo:before {
  content: "\f03e"; }

.icon-picture-o:before {
  content: "\f03e"; }

.icon-map-marker:before {
  content: "\f041"; }

.icon-check-square-o:before {
  content: "\f046"; }

.icon-calendar:before {
  content: "\f073"; }

.icon-bar-chart:before {
  content: "\f080"; }

.icon-bar-chart-o:before {
  content: "\f080"; }

.icon-comments:before {
  content: "\f086"; }

.icon-bell-o:before {
  content: "\f0a2"; }

.icon-envelope:before {
  content: "\f0e0"; }

.icon-bell:before {
  content: "\f0f3"; }

.icon-angle-right:before {
  content: "\f105"; }

.login {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("../img/background-merge.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed; }
  .login__simpeel-logo {
    height: 100%;
    flex: 7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .login__simpeel-logo img {
      width: 80%;
      height: auto; }
  .login__form {
    background-color: rgba(255, 255, 255, 0.3);
    height: 100vh;
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 6rem; }
  .login__form-title {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 2.4rem;
    color: #fff; }
  .login__input-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem; }
    .login__input-wrapper--icon {
      color: #fff;
      padding: 0 2rem;
      font-size: 2.4rem; }

.home {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .home__logo {
    padding: 2rem;
    width: 25rem;
    height: 25rem; }
  .home__info {
    padding: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column; }
  .home__info-list {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .home__info-list p:nth-child(1) {
      flex: 2;
      font-weight: bold;
      font-size: 2.4rem; }
    .home__info-list p:nth-child(2) {
      flex: 8;
      font-size: 2.4rem; }

.upload__content {
  margin-top: 4rem; }

.add-upload__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .add-upload__form-table td, .add-upload__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .add-upload__form-table tr td:nth-child(1) {
    width: 1%; }
  .add-upload__form-table tr td:nth-child(2) {
    width: 20%; }
  .add-upload__form-table tr td:nth-child(4) {
    width: 30%; }
  .add-upload__form-table td {
    font-size: 1.6rem; }
  .add-upload__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .add-upload__form-table__actions {
    display: flex; }
    .add-upload__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.add-upload__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.add-upload__table-footer td:first-child {
  text-align: right; }

.manage-registration__custom-form-row {
  display: flex;
  flex-direction: row; }
  .manage-registration__custom-form-row__field {
    display: flex;
    flex-direction: row;
    flex: 1; }
    .manage-registration__custom-form-row__field:not(:nth-child(1)) {
      margin-left: 2rem; }

.manage-registration__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
  .manage-registration__button-wrapper button:nth-child(2) {
    margin-left: 2rem; }

.student-list__header {
  display: flex;
  flex-direction: row; }

.student-list__button {
  width: 100%; }
  .student-list__button:nth-child(2) {
    margin-left: 2rem; }

.student-list__content {
  margin-top: 4rem; }

.upload-student__content {
  margin-top: 4rem; }

.student-number__header {
  display: flex;
  flex-direction: column; }

.student-number__header-detail {
  display: flex;
  flex-direction: row; }
  .student-number__header-detail p:nth-child(1) {
    flex: 1; }
  .student-number__header-detail p:nth-child(2) {
    flex: 9; }

.student-number__content-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.klapper__content-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; }

.student-kbk__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .student-kbk__table--number {
    width: 1%; }
  .student-kbk__table td, .student-kbk__table th {
    border: 1px solid #000;
    padding: 8px; }
  .student-kbk__table td {
    font-size: 1.6rem; }
  .student-kbk__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .student-kbk__table__actions {
    display: flex; }
    .student-kbk__table__actions button:nth-child(1) {
      margin-right: 1rem; }

.student-grade__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem; }

.student-grade__table-wrapper {
  min-width: 100%;
  overflow-x: auto; }

.student-grade__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .student-grade__table--number {
    width: 1%; }
  .student-grade__table td, .student-grade__table th {
    border: 1px solid #000;
    padding: 8px; }
  .student-grade__table td {
    font-size: 1.6rem; }
  .student-grade__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .student-grade__table__actions {
    display: flex; }
    .student-grade__table__actions button:nth-child(1) {
      margin-right: 1rem; }

.student-book__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.student-book__content-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-staff__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-staff__form-table td, .manage-staff__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-staff__form-table > tr > td:nth-child(1) {
    width: 1%; }
  .manage-staff__form-table > tr > td:nth-child(2) {
    width: 20%; }
  .manage-staff__form-table > tr > td:nth-child(4) {
    width: 30%; }
  .manage-staff__form-table td {
    font-size: 1.6rem; }
  .manage-staff__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-staff__form-table__actions {
    display: flex; }
    .manage-staff__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-staff__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-staff__table-footer td:first-child {
  text-align: right; }

.manage-staff-form__custom-form-row {
  display: flex;
  flex-direction: row; }
  .manage-staff-form__custom-form-row__field {
    display: flex;
    flex-direction: row;
    flex: 1; }
    .manage-staff-form__custom-form-row__field:not(:nth-child(1)) {
      margin-left: 1rem; }

.manage-staff-form__custom-form-row2 {
  display: flex;
  flex-direction: row; }
  .manage-staff-form__custom-form-row2__field {
    display: flex;
    flex-direction: row;
    flex: 1; }
    .manage-staff-form__custom-form-row2__field:not(:nth-child(1)) {
      margin-left: 45rem; }

.manage-staff-form__custom-form-column {
  display: flex;
  flex-direction: column; }
  .manage-staff-form__custom-form-column__field {
    display: flex;
    flex-direction: column;
    flex: 1; }
    .manage-staff-form__custom-form-column__field:not(:nth-child(1)) {
      margin-left: 2rem; }

.manage-staff-form__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
  .manage-staff-form__button-wrapper button:nth-child(2) {
    margin-left: 2rem; }

.manage-staff2__custom-form-row {
  display: flex;
  flex-direction: row; }
  .manage-staff2__custom-form-row__field {
    display: flex;
    flex-direction: row;
    flex: 1; }
    .manage-staff2__custom-form-row__field:not(:nth-child(1)) {
      margin-left: 2rem; }

.manage-staff2__custom-form-row-space {
  margin-left: 1rem;
  width: 100%; }

.manage-staff2__custom-form-row-city {
  display: flex;
  flex-direction: row;
  margin-top: -50rem; }
  .manage-staff2__custom-form-row-city__field {
    display: flex;
    flex-direction: row;
    flex: 1; }
    .manage-staff2__custom-form-row-city__field:not(:nth-child(1)) {
      margin-left: 2rem; }

.manage-staff2__custom-form-row-input {
  display: flex;
  flex-direction: row; }
  .manage-staff2__custom-form-row-input__field {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 50%; }
    .manage-staff2__custom-form-row-input__field:not(:nth-child(1)) {
      margin-left: 1rem; }

.manage-staff2__custom-form-margin-left {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  width: 100%; }

.manage-staff2__custom-form-column {
  display: flex;
  flex-direction: column; }
  .manage-staff2__custom-form-column__field {
    display: flex;
    flex-direction: column;
    flex: 1; }
    .manage-staff2__custom-form-column__field:not(:nth-child(1)) {
      margin-left: 2rem; }

.manage-staff2__custom-form-column-city {
  display: flex;
  flex-direction: column; }
  .manage-staff2__custom-form-column-city__field {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: -7rem; }
    .manage-staff2__custom-form-column-city__field:not(:nth-child(1)) {
      margin-left: 2rem; }

.manage-staff2__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
  .manage-staff2__button-wrapper button:nth-child(2) {
    margin-left: 2rem; }

.staff-list__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem; }

.staff-list__button {
  width: 100%; }
  .staff-list__button:nth-child(2) {
    margin-left: 2rem; }

.staff-list__content {
  margin-top: 4rem; }

.staff-list__sk-button {
  margin-left: 1rem; }

.upload-staff__content {
  margin-top: 4rem; }

.information-letter__content {
  margin-top: 2rem; }

.manage-information-letter__title {
  font-size: 3.6rem; }

.manage-information-letter__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-information-letter__form-table td, .manage-information-letter__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-information-letter__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-information-letter__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-information-letter__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-information-letter__form-table td {
    font-size: 1.6rem; }
  .manage-information-letter__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-information-letter__form-table__actions {
    display: flex; }
    .manage-information-letter__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-information-letter__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-information-letter__table-footer td:first-child {
  text-align: right; }

.manage-information-letter__button {
  display: flex;
  flex-direction: row; }
  .manage-information-letter__button button:nth-child(2) {
    margin-left: 2rem; }

.manage-information-letter__tab {
  display: flex;
  width: 100%;
  height: auto;
  flex: 6;
  margin-bottom: 1rem; }
  .manage-information-letter__tab--child {
    flex-direction: row; }

.manage-information-letter__divider {
  width: 5rem;
  height: auto;
  display: inline-block; }

.manage-information-letter__error {
  width: 100%;
  height: auto;
  color: red;
  text-align: 'right'; }

.job-application__content {
  margin-top: 4rem; }

.manage-job-application__title {
  font-size: 3.6rem; }

.manage-job-application__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-job-application__form-table td, .manage-job-application__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-job-application__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-job-application__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-job-application__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-job-application__form-table td {
    font-size: 1.6rem; }
  .manage-job-application__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-job-application__form-table__actions {
    display: flex; }
    .manage-job-application__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-job-application__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-job-application__table-footer td:first-child {
  text-align: right; }

.manage-job-application__button {
  display: flex;
  flex-direction: row; }
  .manage-job-application__button button:nth-child(2) {
    margin-left: 2rem; }

.request__content {
  margin-top: 4rem; }

.manage-request__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-request__form-table td, .manage-request__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-request__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-request__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-request__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-request__form-table td {
    font-size: 1.6rem; }
  .manage-request__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-request__form-table__actions {
    display: flex; }
    .manage-request__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-request__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-request__table-footer td:first-child {
  text-align: right; }

.discontinuance__content {
  margin-top: 4rem; }

.manage-discontinuance__title {
  font-size: 3.6rem; }

.manage-discontinuance__button {
  display: flex;
  flex-direction: row; }
  .manage-discontinuance__button button:nth-child(2) {
    margin-left: 2rem; }

.discontinuance-description__content {
  margin-top: 4rem; }

.manage-discontinuance-description__title {
  font-size: 3.6rem; }

.manage-discontinuance-description__button {
  display: flex;
  flex-direction: row; }
  .manage-discontinuance-description__button button:nth-child(2) {
    margin-left: 2rem; }

.assignment__content {
  margin-top: 4rem; }

.manage-assignment__title {
  font-size: 3.6rem; }

.manage-assignment__button {
  display: flex;
  flex-direction: row; }
  .manage-assignment__button button:nth-child(2) {
    margin-left: 2rem; }

.payroll__content {
  margin-top: 4rem; }

.manage-payroll__title {
  font-size: 3.6rem; }

.manage-payroll__button {
  display: flex;
  flex-direction: row; }
  .manage-payroll__button button:nth-child(2) {
    margin-left: 2rem; }

.salary-report__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right; }

.salary-report__img {
  display: block;
  width: 100%;
  height: 17rem;
  object-fit: cover; }

.salary-report__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .salary-report__table td, .salary-report__table th {
    border: 0px solid #000;
    padding: 8px; }
  .salary-report__table tr td:nth-child(1), .salary-report__table tr th:nth-child(1) {
    width: 1%; }
  .salary-report__table td {
    font-size: 1.6rem; }
  .salary-report__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .salary-report__table__actions {
    display: flex; }
    .salary-report__table__actions button:nth-child(1) {
      margin-right: 1rem; }
  .salary-report__table tr:nth-child(1) th, .salary-report__table tr:nth-child(2) th, .salary-report__table tr:nth-child(3) th {
    text-align: center; }

.retire__content {
  margin-top: 4rem; }

.manage-retire__title {
  font-size: 3.6rem; }

.manage-retire__button {
  display: flex;
  flex-direction: row; }
  .manage-retire__button button:nth-child(2) {
    margin-left: 2rem; }

.promotion__content {
  margin-top: 4rem; }

.manage-promotion__title {
  font-size: 3.6rem; }

.manage-promotion__button {
  display: flex;
  flex-direction: row; }
  .manage-promotion__button button:nth-child(2) {
    margin-left: 2rem; }

.employee-contract__content {
  margin-top: 4rem; }

.manage-employee-contract__title {
  font-size: 3.6rem; }

.manage-employee-contract__button {
  display: flex;
  flex-direction: row; }
  .manage-employee-contract__button button:nth-child(2) {
    margin-left: 2rem; }

.teacher-contract__content {
  margin-top: 4rem; }

.manage-teacher-contract__title {
  font-size: 3.6rem; }

.manage-teacher-contract__button {
  display: flex;
  flex-direction: row; }
  .manage-teacher-contract__button button:nth-child(2) {
    margin-left: 2rem; }

.position__content {
  margin-top: 4rem; }

.manage-position__title {
  font-size: 3.6rem; }

.manage-position__button {
  display: flex;
  flex-direction: row; }
  .manage-position__button button:nth-child(2) {
    margin-left: 2rem; }

.permanent__content {
  margin-top: 4rem; }

.manage-permanent__title {
  font-size: 3.6rem; }

.manage-permanent__button {
  display: flex;
  flex-direction: row; }
  .manage-permanent__button button:nth-child(2) {
    margin-left: 2rem; }

.job-period__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .job-period__form-table td, .job-period__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .job-period__form-table tr td:nth-child(1) {
    width: 1%; }
  .job-period__form-table tr td:nth-child(2) {
    width: 20%; }
  .job-period__form-table tr td:nth-child(4) {
    width: 30%; }
  .job-period__form-table td {
    font-size: 1.6rem; }
  .job-period__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .job-period__form-table__actions {
    display: flex; }
    .job-period__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.job-period__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.job-period__table-footer td:first-child {
  text-align: right; }

.teacher-employee__content {
  overflow-x: auto; }

.teacher-employee__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .teacher-employee__form-table td, .teacher-employee__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .teacher-employee__form-table tr td:nth-child(1) {
    width: 1%; }
  .teacher-employee__form-table tr td:nth-child(2) {
    width: 20%; }
  .teacher-employee__form-table tr td:nth-child(4) {
    width: 30%; }
  .teacher-employee__form-table td {
    font-size: 1.6rem; }
  .teacher-employee__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .teacher-employee__form-table__actions {
    display: flex; }
    .teacher-employee__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.teacher-employee__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.teacher-employee__table-footer td:first-child {
  text-align: right; }

.preview_sk__content {
  margin: 3rem;
  width: 18cm;
  height: auto;
  position: absolute;
  left: -5px;
  background-position-x: left;
  page-break-after: always;
  page-break-inside: avoid; }

.preview_sk__field {
  margin-top: -4rem;
  margin-left: 70rem;
  font-weight: bold; }

.preview_sk__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem; }

.preview_sk__header-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .preview_sk__header-top--left {
    font-size: 12px;
    font-weight: bold; }
  .preview_sk__header-top--right {
    font-size: 12px;
    font-weight: bold; }

.preview_sk__header-title {
  margin-top: 2rem;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  margin-top: 1rem; }
  .preview_sk__header-title--column {
    flex-direction: column;
    text-align: center; }
    .preview_sk__header-title--column--bold {
      font-weight: bold; }

.preview_sk__header-title2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .preview_sk__header-title2--left {
    font-size: 12px;
    flex-direction: column; }
  .preview_sk__header-title2--right {
    font-size: 12px;
    flex-direction: column; }

.preview_sk__body {
  margin-top: 1rem;
  margin-left: 1rem; }

.preview_sk__table {
  table-layout: auto;
  border-collapse: separate;
  width: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
  border-spacing: 0 0.3em; }
  .preview_sk__table td, .preview_sk__table th {
    padding-right: 1rem;
    color: #000; }
  .preview_sk__table tr td:nth-child(1), .preview_sk__table tr th:nth-child(1) {
    width: 1%; }
  .preview_sk__table td.batas {
    margin-top: 4rem;
    font-size: 12px;
    font-weight: bold;
    text-align: center; }
  .preview_sk__table td.judul {
    font-size: 12px;
    white-space: nowrap; }
  .preview_sk__table td.titik {
    font-size: 12px;
    width: 1px; }
  .preview_sk__table td {
    font-size: 12px; }
  .preview_sk__table tr.batas {
    height: 1rem; }
  .preview_sk__table th {
    font-size: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }

.preview_sk__ket {
  display: flex;
  font-size: 12px;
  margin-left: 2rem;
  margin-right: 2rem; }

.preview_sk tfoot td {
  font-weight: bold; }
  .preview_sk tfoot td:last-child {
    border-left: 5px double #000000; }

.preview_sk tfoot tr:first-child td {
  border-top: 5px double #000000; }

.preview_sk__footer {
  padding: 1rem 0 0 0;
  border: 1px solid #000; }

.preview_sk__footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .preview_sk__footer-top--left {
    padding: 0 1rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: bold; }
  .preview_sk__footer-top--right {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .preview_sk__footer-top--right p {
      font-size: 12px;
      font-weight: bold; }
  .preview_sk__footer-top--total {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000; }

.preview_sk__footer-bottom {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #000; }
  .preview_sk__footer-bottom div {
    min-height: 10rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-top: 10rem; }
    .preview_sk__footer-bottom div p:nth-child(2) {
      font-weight: bold; }
    .preview_sk__footer-bottom div:not(:last-child) {
      border-right: 1px solid #000; }

.preview_sk__button {
  position: relative;
  width: 100%; }

.preview_slip__content {
  margin: 3rem;
  width: 14.8cm;
  height: auto;
  position: absolute;
  left: -30px;
  background-position-x: left;
  page-break-after: always;
  page-break-inside: avoid; }

.preview_slip__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem; }

.preview_slip__header-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 2rem; }
  .preview_slip__header-top--left {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    align-items: flex-start; }
  .preview_slip__header-top--right {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    align-items: flex-end; }

.preview_slip__header-title {
  margin-top: 2rem;
  display: flex;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 12px;
  justify-content: center;
  align-items: center; }
  .preview_slip__header-title--column {
    flex-direction: column;
    text-align: center; }
    .preview_slip__header-title--column--bold {
      font-weight: normal; }

.preview_slip__table {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%; }
  .preview_slip__table td,
  .preview_slip__table th {
    color: #000; }
  .preview_slip__table tr td:nth-child(1),
  .preview_slip__table tr th:nth-child(1) {
    width: 1%; }
  .preview_slip__table td.batas {
    margin-top: 4rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-align: center; }
  .preview_slip__table td.judul {
    font-size: 12px;
    white-space: nowrap; }
  .preview_slip__table td.titik {
    font-size: 12px;
    width: 1px; }
  .preview_slip__table td {
    font-size: 12px; }
  .preview_slip__table tr.batas {
    height: 1rem; }
  .preview_slip__table th {
    font-size: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }

.preview_slip__row {
  display: flex;
  flex-direction: row; }

.preview_slip__col {
  display: flex;
  flex-direction: column; }

.preview_slip__body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 2rem;
  margin-right: 2rem; }
  .preview_slip__body--left {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    align-items: flex-start; }
  .preview_slip__body--right {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    align-items: flex-end; }
  .preview_slip__body ol {
    width: 100%;
    padding-left: 20px;
    list-style-type: lower-alpha; }

.preview_slip__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 2rem; }
  .preview_slip__footer--left {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    align-items: flex-end;
    margin-right: 20px; }
    .preview_slip__footer--left--inside {
      margin-right: 5px;
      text-decoration: underline;
      text-align: right; }
  .preview_slip__footer--right {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    align-items: center; }

.finance {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .finance__list {
    margin-right: 2rem; }
    .finance__list img {
      width: 100px;
      height: 100px; }
    .finance__list button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      padding: 2rem;
      border-radius: 10px; }

.bookkeeping {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap; }
  .bookkeeping__list {
    margin-right: 2rem;
    margin-top: 20px;
    margin-bottom: 20px; }
    .bookkeeping__list img {
      width: 100px;
      height: 100px; }
    .bookkeeping__list button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      padding: 2rem;
      border-radius: 10px; }

.budgeting {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .budgeting__list {
    margin-right: 2rem; }
    .budgeting__list img {
      width: 100px;
      height: 100px; }
    .budgeting__list button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      padding: 2rem;
      border-radius: 10px; }

.budget__content {
  margin-top: 4rem; }

.budget__search {
  margin-left: 80%;
  position: relative;
  width: 500px; }

.add-budget__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .add-budget__form-table td, .add-budget__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .add-budget__form-table tr td:nth-child(1) {
    width: 1%; }
  .add-budget__form-table tr td:nth-child(2) {
    width: 20%; }
  .add-budget__form-table tr td:nth-child(4) {
    width: 30%; }
  .add-budget__form-table td {
    font-size: 1.6rem; }
  .add-budget__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .add-budget__form-table__actions {
    display: flex; }
    .add-budget__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.add-budget__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.add-budget__table-footer td:first-child {
  text-align: right; }

.manage-budget__table-wrapper {
  min-width: 100%; }

.manage-budget__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem;
  position: relative; }
  .manage-budget__form-table td, .manage-budget__form-table th {
    border: 1px solid #000;
    padding: 8px; }
    .manage-budget__form-table td.col_rekomendasi, tr.total .manage-budget__form-table td.col_rekomendasi, .manage-budget__form-table th.col_rekomendasi, tr.total .manage-budget__form-table th.col_rekomendasi {
      background-color: #909090; }
  .manage-budget__form-table tr.total td.col_rekomendasi {
    background-color: #909090; }
  .manage-budget__form-table td {
    font-size: 1.6rem; }
  .manage-budget__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center; }
  .manage-budget__form-table thead tr th {
    position: sticky;
    top: -29px;
    z-index: 2; }
  .manage-budget__form-table thead tr:nth-child(2) th {
    top: 21px; }

.manage-budget__add-more {
  display: flex;
  align-items: center; }
  .manage-budget__add-more h3 {
    min-width: 25%;
    width: auto; }

.manage-budget__row {
  min-width: 13rem; }
  .manage-budget__row.small {
    min-width: 8rem; }
  .manage-budget__row--30 {
    min-width: 30rem; }
  .manage-budget__row.nominal .input__container {
    justify-content: flex-end; }

.manage-budget__table-footer td:first-child {
  text-align: right; }

.manage-budget__action-button {
  display: flex;
  flex-direction: row; }
  .manage-budget__action-button button:nth-child(2) {
    margin-left: 1rem; }

.manage-budget__total-transfer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.manage-budget__summary td {
  text-align: right;
  font-weight: bold; }

.upload-rapbu__content {
  margin-top: 4rem; }

.add-upload-rapbu__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .add-upload-rapbu__form-table td, .add-upload-rapbu__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .add-upload-rapbu__form-table tr td:nth-child(1) {
    width: 1%; }
  .add-upload-rapbu__form-table tr td:nth-child(2) {
    width: 20%; }
  .add-upload-rapbu__form-table tr td:nth-child(4) {
    width: 30%; }
  .add-upload-rapbu__form-table td {
    font-size: 1.6rem; }
  .add-upload-rapbu__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .add-upload-rapbu__form-table__actions {
    display: flex; }
    .add-upload-rapbu__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.add-upload-rapbu__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.add-upload-rapbu__table-footer td:first-child {
  text-align: right; }

.non-budget__content {
  margin-top: 4rem; }

.manage-non-budget__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-non-budget__form-table td, .manage-non-budget__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-non-budget__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-non-budget__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-non-budget__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-non-budget__form-table td {
    font-size: 1.6rem; }
  .manage-non-budget__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-non-budget__form-table__actions {
    display: flex; }
    .manage-non-budget__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-non-budget__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-non-budget__table-footer td:first-child {
  text-align: right; }

.budget-transfer__content {
  margin-top: 4rem; }

.budget-transfer__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .budget-transfer__form-table td, .budget-transfer__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .budget-transfer__form-table tr td:nth-child(1) {
    width: 1%; }
  .budget-transfer__form-table tr td:nth-child(2) {
    width: 20%; }
  .budget-transfer__form-table tr td:nth-child(4) {
    width: 30%; }
  .budget-transfer__form-table td {
    font-size: 1.6rem; }
  .budget-transfer__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .budget-transfer__form-table__actions {
    display: flex; }
    .budget-transfer__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.budget-transfer__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.budget-transfer__table-footer td:first-child {
  text-align: right; }

.manage-budget-transfer__row-form {
  display: flex;
  flex-direction: row; }

.manage-budget-transfer__form-content {
  display: flex;
  width: calc(50% - 1rem);
  flex-direction: row;
  margin-right: 2rem; }
  .manage-budget-transfer__form-content:nth-child(even) {
    margin-right: 0; }

.manage-budget-transfer table {
  width: 100%;
  table-layout: fixed; }

.manage-budget-transfer__table-header {
  background-color: rgba(0, 0, 0, 0.3); }

.manage-budget-transfer__table-content {
  height: 40rem;
  overflow-y: auto;
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.5); }

.manage-budget-transfer th {
  font-size: 1.6rem;
  padding: 2rem 1.5rem;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.5); }

.manage-budget-transfer td {
  font-size: 1.6rem;
  padding: 1.5rem;
  text-align: left;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.5); }

.manage-budget-transfer tr td:nth-child(1), .manage-budget-transfer tr th:nth-child(1) {
  width: 5%;
  text-align: center; }

.manage-budget-transfer__form-table {
  margin-bottom: 4rem; }

.fund-request__content {
  margin-top: 4rem; }

.manage-fund-request__row-form {
  display: flex;
  flex-direction: row; }

.manage-fund-request__form-content {
  display: flex;
  width: calc(50% - 1rem);
  flex-direction: row;
  margin-right: 2rem; }
  .manage-fund-request__form-content:nth-child(even) {
    margin-right: 0; }

.manage-fund-request table {
  width: 100%;
  table-layout: fixed; }

.manage-fund-request__table-header {
  background-color: rgba(0, 0, 0, 0.3); }

.manage-fund-request__table-content {
  height: 40rem;
  overflow-y: auto;
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.5); }

.manage-fund-request th {
  font-size: 1.6rem;
  padding: 2rem 0;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.5); }

.manage-fund-request td {
  font-size: 1.6rem;
  padding: 1.5rem;
  text-align: left;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.5);
  word-break: break-word; }
  .manage-fund-request td.nominal {
    text-align: right; }
    .manage-fund-request td.nominal input, .manage-fund-request td.nominal .input__container {
      text-align: right; }
    .manage-fund-request td.nominal .input__preview {
      display: block;
      text-align: right;
      width: 100%; }

.manage-fund-request tr td:nth-child(1), .manage-fund-request tr th:nth-child(1) {
  width: 5%;
  text-align: center; }

.manage-fund-request tr td.col_sm, .manage-fund-request tr th.col_sm {
  min-width: 3%;
  padding: 2rem 0.5rem;
  text-align: center; }

.manage-fund-request__form-table {
  margin-bottom: 4rem; }

.manage-fund-request__total-transfer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.realization__content {
  margin-top: 4rem; }

.manage-realization__row-form {
  display: flex;
  flex-direction: row; }

.manage-realization__form-content {
  display: flex;
  width: calc(50% - 1rem);
  flex-direction: row;
  margin-right: 2rem; }
  .manage-realization__form-content:nth-child(even) {
    margin-right: 0; }

.manage-realization table {
  width: 100%;
  table-layout: fixed; }

.manage-realization__table-header {
  background-color: rgba(0, 0, 0, 0.3); }

.manage-realization__table-content {
  height: 40rem;
  overflow-y: auto;
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.5); }

.manage-realization th {
  font-size: 1.6rem;
  padding: 2rem 1.5rem;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.5); }

.manage-realization td {
  font-size: 1.6rem;
  padding: 1.5rem;
  text-align: left;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.5); }

.manage-realization tr td:nth-child(1), .manage-realization tr th:nth-child(1) {
  width: 5%;
  text-align: center; }

.manage-realization__form-table {
  margin-bottom: 4rem; }

.cash-journal__content {
  margin-top: 4rem; }

.cash-journal-closing {
  font-size: 1.5em; }
  .cash-journal-closing table {
    border-spacing: 0; }
  .cash-journal-closing td {
    padding: 5px; }
  .cash-journal-closing th {
    text-align: left; }
  .cash-journal-closing .list-number {
    width: 20px; }
  .cash-journal-closing .row-total-label {
    width: 25px; }
  .cash-journal-closing .row-total-field {
    width: 220px;
    text-align: right;
    padding-right: 20px; }
    .cash-journal-closing .row-total-field input {
      text-align: right;
      padding: 0;
      border: 0;
      border-bottom: 2px dotted #000;
      border-radius: 0; }
  .cash-journal-closing .space {
    width: 200px; }
  .cash-journal-closing .counter {
    width: 50px; }
  .cash-journal-closing .node {
    width: 100%; }
  .cash-journal-closing tr.sub-total .title, .cash-journal-closing tr.sub-total .row-total-label, .cash-journal-closing tr.sub-total .row-total-field, .cash-journal-closing tr.sub-total .space {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
    border-top: 2px solid #000; }
  .cash-journal-closing tr.sub-total .row-total-field {
    text-align: right; }
  .cash-journal-closing tr.total .title {
    text-align: center;
    font-weight: bold; }
  .cash-journal-closing tr.total td {
    border-bottom: 2px solid #000; }
  .cash-journal-closing tr.total:last-child td {
    border-bottom: 2px double #000; }
  .cash-journal-closing table.content {
    width: 100%;
    margin-top: 20px; }
    .cash-journal-closing table.content th {
      padding: 5px; }

.manage-cash-journal__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-cash-journal__form-table td, .manage-cash-journal__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-cash-journal__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-cash-journal__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-cash-journal__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-cash-journal__form-table td {
    font-size: 1.6rem; }
  .manage-cash-journal__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-cash-journal__form-table__actions {
    display: flex; }
    .manage-cash-journal__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-cash-journal__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-cash-journal__table-footer td:first-child {
  text-align: right; }

.detail-cash-journal__content {
  margin-top: 4rem; }

.detail-cash-journal__header {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #000; }

.detail-cash-journal__header-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .detail-cash-journal__header-top--left {
    font-size: 1.6rem;
    font-weight: bold; }
  .detail-cash-journal__header-top--right {
    font-size: 1.6rem;
    font-weight: bold; }

.detail-cash-journal__header-title {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center; }

.detail-cash-journal__body {
  margin-top: 1rem; }

.detail-cash-journal__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .detail-cash-journal__table td, .detail-cash-journal__table th {
    border: 1px solid #000;
    padding: 8px; }
  .detail-cash-journal__table tr td:nth-child(1), .detail-cash-journal__table tr th:nth-child(1) {
    width: 1%; }
  .detail-cash-journal__table td.nominal {
    text-align: right; }
  .detail-cash-journal__table td {
    font-size: 1.6rem; }
  .detail-cash-journal__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center; }

.detail-cash-journal tfoot td {
  font-weight: bold; }
  .detail-cash-journal tfoot td:last-child {
    border-left: 5px double #000000; }

.detail-cash-journal tfoot tr:first-child td {
  border-top: 5px double #000000; }

.detail-cash-journal thead th:last-child, .detail-cash-journal thead td:last-child, .detail-cash-journal tbody th:last-child, .detail-cash-journal tbody td:last-child, .detail-cash-journal tfoot th:last-child, .detail-cash-journal tfoot td:last-child {
  border-left: 5px double #000000; }

.detail-cash-journal__footer {
  padding: 1rem 0 0 0;
  border: 1px solid #000; }

.detail-cash-journal__footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .detail-cash-journal__footer-top--left {
    padding: 0 1rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    font-weight: bold; }
  .detail-cash-journal__footer-top--right {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .detail-cash-journal__footer-top--right p {
      font-size: 1.6rem;
      font-weight: bold; }
  .detail-cash-journal__footer-top--total {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000; }

.detail-cash-journal__footer-bottom {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #000; }
  .detail-cash-journal__footer-bottom div {
    min-height: 10rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem; }
    .detail-cash-journal__footer-bottom div p:nth-child(2) {
      font-weight: bold; }
    .detail-cash-journal__footer-bottom div:not(:last-child) {
      border-right: 1px solid #000; }

.bank-journal__content {
  margin-top: 4rem; }

.bank-journal__action-button {
  margin-left: 1rem; }

.manage-bank-journal__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-bank-journal__form-table td, .manage-bank-journal__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-bank-journal__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-bank-journal__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-bank-journal__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-bank-journal__form-table td {
    font-size: 1.6rem; }
  .manage-bank-journal__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-bank-journal__form-table__actions {
    display: flex; }
    .manage-bank-journal__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-bank-journal__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-bank-journal__table-footer td:first-child {
  text-align: right; }

.detail-bank-journal__content {
  margin-top: 4rem; }

.detail-bank-journal__header {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #000; }

.detail-bank-journal__header-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .detail-bank-journal__header-top--left {
    font-size: 1.6rem;
    font-weight: bold; }
  .detail-bank-journal__header-top--right {
    font-size: 1.6rem;
    font-weight: bold; }

.detail-bank-journal__header-title {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center; }

.detail-bank-journal__body {
  margin-top: 1rem; }

.detail-bank-journal__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .detail-bank-journal__table td, .detail-bank-journal__table th {
    border: 1px solid #000;
    padding: 8px; }
  .detail-bank-journal__table tr td:nth-child(1), .detail-bank-journal__table tr th:nth-child(1) {
    width: 1%; }
  .detail-bank-journal__table td {
    font-size: 1.6rem; }
  .detail-bank-journal__table td.nominal {
    text-align: right; }
  .detail-bank-journal__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center; }

.detail-bank-journal tfoot td {
  font-weight: bold; }
  .detail-bank-journal tfoot td:last-child {
    border-left: 5px double #000000; }

.detail-bank-journal tfoot tr:first-child td {
  border-top: 5px double #000000; }

.detail-bank-journal thead th:last-child, .detail-bank-journal thead td:last-child, .detail-bank-journal tbody th:last-child, .detail-bank-journal tbody td:last-child, .detail-bank-journal tfoot th:last-child, .detail-bank-journal tfoot td:last-child {
  border-left: 5px double #000000; }

.detail-bank-journal__footer {
  padding: 1rem 0 0 0;
  border: 1px solid #000; }

.detail-bank-journal__footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .detail-bank-journal__footer-top--left {
    padding: 0 1rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    font-weight: bold; }
  .detail-bank-journal__footer-top--right {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .detail-bank-journal__footer-top--right p {
      font-size: 1.6rem;
      font-weight: bold; }
  .detail-bank-journal__footer-top--total {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000; }

.detail-bank-journal__footer-bottom {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #000; }
  .detail-bank-journal__footer-bottom div {
    min-height: 10rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem; }
    .detail-bank-journal__footer-bottom div p:nth-child(2) {
      font-weight: bold; }
    .detail-bank-journal__footer-bottom div:not(:last-child) {
      border-right: 1px solid #000; }

.payment-journal__content {
  margin-top: 4rem; }

.manage-payment-journal__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-payment-journal__form-table td, .manage-payment-journal__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-payment-journal__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-payment-journal__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-payment-journal__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-payment-journal__form-table td {
    font-size: 1.6rem; }
  .manage-payment-journal__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-payment-journal__form-table__actions {
    display: flex; }
    .manage-payment-journal__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-payment-journal__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-payment-journal__table-footer td:first-child {
  text-align: right; }

.adjustment-journal__add-button {
  margin-bottom: 4rem; }

.adjustment-journal__date {
  width: 30%; }

.adjustment-journal__content {
  margin-top: 4rem; }

.view-adjustment-journal-table-container {
  margin-top: 20px; }
  .view-adjustment-journal-table-container > tr {
    vertical-align: top; }
    .view-adjustment-journal-table-container > tr > td {
      vertical-align: top; }
  .view-adjustment-journal-table-container .selected {
    background: #CCCCCC; }

.manage-adjustment-journal th.selection, .manage-adjustment-journal td.selection {
  text-align: center;
  width: 30px; }

.manage-adjustment-journal th.coa, .manage-adjustment-journal td.coa {
  width: 250px; }

.closing-journal__add-button {
  margin-bottom: 4rem; }

.closing-journal__date {
  width: 30%; }

.closing-journal__content {
  margin-top: 4rem; }

.manage-closing-journal__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-closing-journal__form-table td, .manage-closing-journal__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-closing-journal__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-closing-journal__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-closing-journal__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-closing-journal__form-table td {
    font-size: 1.6rem; }
  .manage-closing-journal__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-closing-journal__form-table__actions {
    display: flex; }
    .manage-closing-journal__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-closing-journal__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-closing-journal__table-footer td:first-child {
  text-align: right; }

.non-kas-journal__add-button {
  margin-bottom: 4rem; }

.non-kas-journal__date {
  width: 30%; }

.non-kas-journal__content {
  margin-top: 4rem; }

.manage-non-kas-journal__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-non-kas-journal__form-table td, .manage-non-kas-journal__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-non-kas-journal__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-non-kas-journal__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-non-kas-journal__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-non-kas-journal__form-table td {
    font-size: 1.6rem; }
  .manage-non-kas-journal__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-non-kas-journal__form-table__actions {
    display: flex; }
    .manage-non-kas-journal__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-non-kas-journal__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-non-kas-journal__table-footer td:first-child {
  text-align: right; }

.general-journal__add-button {
  margin-bottom: 4rem; }

.general-journal__date {
  width: 30%; }

.general-journal__content {
  margin-top: 4rem; }

.manage-general-journal__form-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .manage-general-journal__form-table td, .manage-general-journal__form-table th {
    border: 1px solid #000;
    padding: 8px; }
  .manage-general-journal__form-table tr td:nth-child(1) {
    width: 1%; }
  .manage-general-journal__form-table tr td:nth-child(2) {
    width: 20%; }
  .manage-general-journal__form-table tr td:nth-child(4) {
    width: 30%; }
  .manage-general-journal__form-table td {
    font-size: 1.6rem; }
  .manage-general-journal__form-table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .manage-general-journal__form-table__actions {
    display: flex; }
    .manage-general-journal__form-table__actions button:nth-child(1) {
      margin-right: 1rem; }

.manage-general-journal__add-more div {
  width: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.manage-general-journal__table-footer td:first-child {
  text-align: right; }

.beginning-balance__custom-form-row {
  display: flex;
  flex-direction: row; }
  .beginning-balance__custom-form-row__field {
    display: flex;
    flex-direction: row;
    flex: 1; }
    .beginning-balance__custom-form-row__field:not(:nth-child(1)) {
      margin-left: 2rem; }

.beginning-balance__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
  .beginning-balance__button-wrapper button:nth-child(2) {
    margin-left: 2rem; }

.cash-journal-report__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.cash-journal-report__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .cash-journal-report__table td, .cash-journal-report__table th {
    border: 1px solid #000;
    padding: 8px; }
    .cash-journal-report__table td.nominal, .cash-journal-report__table th.nominal {
      text-align: right;
      width: 155px;
      min-width: 155px; }
  .cash-journal-report__table tr td:nth-child(1), .cash-journal-report__table tr th:nth-child(1) {
    width: 1%; }
  .cash-journal-report__table td {
    font-size: 1.6rem; }
  .cash-journal-report__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .cash-journal-report__table__actions {
    display: flex; }
    .cash-journal-report__table__actions button:nth-child(1) {
      margin-right: 1rem; }
  .cash-journal-report__table tr:nth-child(1) th, .cash-journal-report__table tr:nth-child(2) th, .cash-journal-report__table tr:nth-child(3) th {
    text-align: center; }
    .cash-journal-report__table tr:nth-child(1) th.nominal, .cash-journal-report__table tr:nth-child(2) th.nominal, .cash-journal-report__table tr:nth-child(3) th.nominal {
      text-align: right; }

.cash-journal-report__action-button {
  margin-left: 1rem; }

.bank-journal-report__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.bank-journal-report__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .bank-journal-report__table td, .bank-journal-report__table th {
    border: 1px solid #000;
    padding: 8px; }
    .bank-journal-report__table td.nominal, .bank-journal-report__table th.nominal {
      text-align: right; }
  .bank-journal-report__table tr td:nth-child(1), .bank-journal-report__table tr th:nth-child(1) {
    width: 1%; }
  .bank-journal-report__table td {
    font-size: 1.6rem; }
  .bank-journal-report__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .bank-journal-report__table__actions {
    display: flex; }
    .bank-journal-report__table__actions button:nth-child(1) {
      margin-right: 1rem; }
  .bank-journal-report__table tr:nth-child(1) th, .bank-journal-report__table tr:nth-child(2) th, .bank-journal-report__table tr:nth-child(3) th {
    text-align: center; }
    .bank-journal-report__table tr:nth-child(1) th.nominal, .bank-journal-report__table tr:nth-child(2) th.nominal, .bank-journal-report__table tr:nth-child(3) th.nominal {
      text-align: right; }

.bank-journal-report__action-button {
  margin-left: 1rem; }

.daily-journal-report__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.daily-journal-report__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .daily-journal-report__table td, .daily-journal-report__table th {
    border: 1px solid #000;
    padding: 8px; }
  .daily-journal-report__table tr td:nth-child(1), .daily-journal-report__table tr th:nth-child(1) {
    width: 1%; }
  .daily-journal-report__table td {
    font-size: 1.6rem; }
  .daily-journal-report__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .daily-journal-report__table__actions {
    display: flex; }
    .daily-journal-report__table__actions button:nth-child(1) {
      margin-right: 1rem; }
  .daily-journal-report__table tr:nth-child(1) th, .daily-journal-report__table tr:nth-child(2) th, .daily-journal-report__table tr:nth-child(3) th {
    text-align: center; }

.daily-journal-report__action-button {
  margin-left: 1rem; }

.big-book-report__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.big-book-report__content {
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 4rem; }

.big-book-report__header {
  padding: 2rem; }

.big-book-report__info {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .big-book-report__info p:nth-child(1) {
    flex: 2;
    font-weight: bold; }
  .big-book-report__info p:nth-child(2) {
    flex: 8; }

.big-book-report__table {
  border-collapse: collapse;
  width: 100%; }
  .big-book-report__table td, .big-book-report__table th {
    border: 1px solid #000;
    padding: 8px; }
    .big-book-report__table td.nominal, .big-book-report__table th.nominal {
      text-align: right;
      width: 15%;
      min-width: 150px; }
  .big-book-report__table tr td:nth-child(1), .big-book-report__table tr th:nth-child(1) {
    width: 1%; }
  .big-book-report__table td {
    font-size: 1.6rem; }
  .big-book-report__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .big-book-report__table__actions {
    display: flex; }
    .big-book-report__table__actions button:nth-child(1) {
      margin-right: 1rem; }
  .big-book-report__table tr:nth-child(1) th, .big-book-report__table tr:nth-child(2) th, .big-book-report__table tr:nth-child(3) th {
    text-align: center; }

.monthly-report__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.monthly-report__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .monthly-report__table td, .monthly-report__table th {
    border: 1px solid #000;
    padding: 8px; }
  .monthly-report__table tr td:nth-child(1), .monthly-report__table tr th:nth-child(1) {
    width: 1%; }
  .monthly-report__table td {
    font-size: 1.6rem; }
  .monthly-report__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .monthly-report__table__actions {
    display: flex; }
    .monthly-report__table__actions button:nth-child(1) {
      margin-right: 1rem; }
  .monthly-report__table tr:nth-child(1) th, .monthly-report__table tr:nth-child(2) th, .monthly-report__table tr:nth-child(3) th {
    text-align: center; }

.daily-and-monthly-report__form {
  margin-bottom: 4rem; }

.daily-and-monthly-report__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.daily-and-monthly-report__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .daily-and-monthly-report__table td, .daily-and-monthly-report__table th {
    border: 1px solid #000;
    padding: 8px; }
    .daily-and-monthly-report__table td.nominal, .daily-and-monthly-report__table th.nominal {
      text-align: right; }
  .daily-and-monthly-report__table tr td:nth-child(1), .daily-and-monthly-report__table tr th:nth-child(1) {
    width: 1%; }
  .daily-and-monthly-report__table td {
    font-size: 1.6rem; }
  .daily-and-monthly-report__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .daily-and-monthly-report__table__actions {
    display: flex; }
    .daily-and-monthly-report__table__actions button:nth-child(1) {
      margin-right: 1rem; }
  .daily-and-monthly-report__table tr:nth-child(1) th, .daily-and-monthly-report__table tr:nth-child(2) th, .daily-and-monthly-report__table tr:nth-child(3) th {
    text-align: center; }
    .daily-and-monthly-report__table tr:nth-child(1) th.nominal, .daily-and-monthly-report__table tr:nth-child(2) th.nominal, .daily-and-monthly-report__table tr:nth-child(3) th.nominal {
      text-align: right; }

.profit-loss-report__form {
  margin-bottom: 4rem; }

.profit-loss-report__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.profit-loss-report__table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 4rem; }
  .profit-loss-report__table thead {
    border-bottom: 2px solid #000000; }
    .profit-loss-report__table thead .code-group {
      border-bottom: 1px solid #000000;
      text-align: center; }
    .profit-loss-report__table thead th {
      text-align: center; }
  .profit-loss-report__table td, .profit-loss-report__table th {
    font-size: 1.3rem;
    border: 1px solid #000;
    border-bottom: none;
    padding: 8px;
    background: none;
    color: #000000;
    border: none; }
    .profit-loss-report__table td.nominal, .profit-loss-report__table th.nominal {
      text-align: right;
      width: 200px; }
  .profit-loss-report__table tr.balance_report_row_class {
    font-weight: bold;
    font-size: 1.8rem; }
    .profit-loss-report__table tr.balance_report_row_class td {
      border: none; }
  .profit-loss-report__table tr.balance_report_row_category {
    font-weight: bold;
    font-size: 1.6rem; }
    .profit-loss-report__table tr.balance_report_row_category td {
      border: none; }
  .profit-loss-report__table tr.balance_report_row_group td {
    border: none; }
  .profit-loss-report__table tr.summary td {
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
    border-right: none;
    background: #cccccc;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center; }
  .profit-loss-report__table tr td.total {
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
    background: #cccccc;
    margin-bottom: 10px; }
  .profit-loss-report__table tr td, .profit-loss-report__table tr th {
    padding: 8px; }
    .profit-loss-report__table tr td.code, .profit-loss-report__table tr th.code {
      width: 90px; }
  .profit-loss-report__table th {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left; }
  .profit-loss-report__table__actions {
    display: flex; }
    .profit-loss-report__table__actions button:nth-child(1) {
      margin-right: 1rem; }

.profit-loss-report__row-black {
  background-color: #2e2f32;
  color: #fff;
  text-align: center; }

.profit-loss-report__row-title {
  font-weight: bold; }

.balance-report__form {
  margin-bottom: 4rem; }

.balance-report__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.balance-report__table_wrapper {
  width: 100%;
  border-collapse: collapse; }
  .balance-report__table_wrapper tr td {
    padding: 0;
    vertical-align: top; }
    .balance-report__table_wrapper tr td:nth-child(1) {
      border-right: 2px solid #000; }
  .balance-report__table_wrapper .balance-report__table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 4rem; }
    .balance-report__table_wrapper .balance-report__table thead {
      border-bottom: 2px solid #000000; }
      .balance-report__table_wrapper .balance-report__table thead .code-group {
        border-bottom: 1px solid #000000;
        text-align: center; }
      .balance-report__table_wrapper .balance-report__table thead th {
        text-align: center; }
    .balance-report__table_wrapper .balance-report__table td, .balance-report__table_wrapper .balance-report__table th {
      font-size: 1.3rem;
      border: 1px solid #000;
      border-bottom: none;
      padding: 8px;
      background: none;
      color: #000000;
      border: none; }
      .balance-report__table_wrapper .balance-report__table td.nominal, .balance-report__table_wrapper .balance-report__table th.nominal {
        text-align: right;
        width: 200px; }
    .balance-report__table_wrapper .balance-report__table tr.balance_report_row_class {
      font-weight: bold;
      font-size: 1.8rem; }
      .balance-report__table_wrapper .balance-report__table tr.balance_report_row_class td {
        border: none; }
    .balance-report__table_wrapper .balance-report__table tr.balance_report_row_category {
      font-weight: bold;
      font-size: 1.6rem; }
      .balance-report__table_wrapper .balance-report__table tr.balance_report_row_category td {
        border: none; }
    .balance-report__table_wrapper .balance-report__table tr.balance_report_row_group td {
      border: none; }
    .balance-report__table_wrapper .balance-report__table tr.summary td {
      border-top: 2px solid #000000;
      border-bottom: 2px solid #000000;
      border-right: none;
      background: #cccccc;
      margin-bottom: 10px;
      padding: 8px;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center; }
    .balance-report__table_wrapper .balance-report__table tr td.total {
      border-top: 2px solid #000000;
      border-bottom: 2px solid #000000;
      background: #cccccc;
      margin-bottom: 10px; }
    .balance-report__table_wrapper .balance-report__table tr td, .balance-report__table_wrapper .balance-report__table tr th {
      padding: 8px; }
      .balance-report__table_wrapper .balance-report__table tr td.code, .balance-report__table_wrapper .balance-report__table tr th.code {
        width: 90px; }
    .balance-report__table_wrapper .balance-report__table th {
      font-size: 1.6rem;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left; }
    .balance-report__table_wrapper .balance-report__table__actions {
      display: flex; }
      .balance-report__table_wrapper .balance-report__table__actions button:nth-child(1) {
        margin-right: 1rem; }

.balance-report__row-black {
  background-color: #2e2f32;
  color: #fff;
  text-align: center; }

.balance-report__row-title {
  font-weight: bold; }

.payment_table__header {
  font-size: 1.4rem; }
  .payment_table__header:first {
    margin: 0 0 20px 0; }

.payment_table__report {
  border-collapse: collapse;
  font-size: 1.4rem;
  width: 40rem;
  margin: 0 0 20px 0; }
  .payment_table__report td, .payment_table__report th {
    padding: 0 5px;
    border: 1px solid #000000; }
  .payment_table__report .index {
    width: 8%; }
  .payment_table__report .periode {
    width: 28%; }
  .payment_table__report .date {
    width: 30%;
    text-align: center; }
  .payment_table__report .nominal {
    text-align: right; }
  .payment_table__report .currency {
    float: left; }

.payment_table__liabilities {
  border-collapse: collapse;
  width: 40rem;
  font-size: 1.4rem;
  margin: 20px 0 20px 0; }
  .payment_table__liabilities tr td:nth-child(1) {
    width: 60%; }
  .payment_table__liabilities .total {
    border-top: 1px double #000000; }
    .payment_table__liabilities .total:nth-child(2) {
      text-align: right; }
  .payment_table__liabilities .nominal {
    text-align: right; }
  .payment_table__liabilities .currency {
    float: left; }

.payment_report__recap {
  border-collapse: collapse;
  font-size: 1.4rem;
  width: 40rem;
  margin: 0 0 20px 0;
  width: 100%; }
  .payment_report__recap td, .payment_report__recap th {
    padding: 0 5px;
    border: 1px solid #000000; }
  .payment_report__recap tr td:nth-child(2) {
    width: 10%; }
  .payment_report__recap tr th.months {
    width: 5%; }
  .payment_report__recap tr td.nominal {
    text-align: right;
    width: 5%; }
  .payment_report__recap .total td {
    background: #DEDEDE;
    font-weight: bold;
    color: #000000; }
  .payment_report__recap .total td:nth-child(1) {
    text-align: center; }

.admission {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .admission__logo {
    padding: 2rem;
    width: 25rem;
    height: 25rem; }
  .admission__info {
    padding: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column; }
  .admission__info-list {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .admission__info-list div:nth-child(1) {
      flex: 3;
      font-weight: bold;
      font-size: 2.4rem; }
    .admission__info-list div:nth-child(2) {
      flex: 7;
      font-size: 2.4rem; }
  .admission__submit {
    margin-top: 4rem; }

.manage-admission__salary-form {
  display: flex;
  flex-direction: row; }
  .manage-admission__salary-form > div:nth-child(2) {
    margin-left: 2rem; }

.manage-admission__button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .manage-admission__button-wrapper div:nth-child(2) {
    margin-left: 2rem; }

.manage-registration {

  &__custom-form {

  }

  &__custom-form-row {
    display: flex;
    flex-direction: row;

    &__field {
      display: flex;
      flex-direction: row;
      flex: 1;

      &:not(:nth-child(1)) {
        margin-left: $gutter-small;
      }
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      &:nth-child(2) {
        margin-left: $gutter-small;
      }
    }
  }
}

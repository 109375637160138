.klapper {

  &__content {

  }

  &__content-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
